import { DateTime, Interval } from 'luxon';
import Adjustment, {
  AdjustmentChangeType,
  AdjustmentPriority,
  AdjustmentType
} from 'models/Adjustment';
import Engineer from 'models/Engineers';
import PatchModel from 'models/Patches';
import ShiftPattern, { OptionalShiftPatternDay } from 'models/ShiftPattern';
import { doesApplyToDay } from 'utils/adjustments';
import {
  dateToDayOfWeek,
  dateToWeekday,
  intervalToArrayByDay
} from 'utils/dates';
import { EngineerCalendarAdjustmentsSummary } from './engineerCalendarDefaultsHelper';

/**
 * get a change from a list of adjustments for a given adjustment type
 *
 * @param adjustments list of adjustments to search
 * @param type the adjustment type to get the change for
 * @returns the first adjustment change that matches the given type, or null
 */
export const getAdjustmentChange = (
  adjustments: Adjustment[],
  type: AdjustmentType
): AdjustmentChangeType | null =>
  adjustments
    .flatMap(adjustment => adjustment.changes)
    .find(change => change.field === type) ?? null;

/**
 * get the applicable day of a shift pattern for a given date
 * @param shiftPattern
 * @param date
 * @returns `OptionalShiftPatternDay`
 */
const getShiftPatternDayForDate = (
  shiftPattern: ShiftPattern,
  date: DateTime
): OptionalShiftPatternDay => {
  const weekday = dateToWeekday(date);
  return Object.entries(shiftPattern).find(([key, value]) =>
    key.toLowerCase() === weekday.toLowerCase() ? value : null
  )?.[1];
};

/**
 * finds the first patch change in a list of adjustments and returns it, or falls back to the default value if none found
 *
 * @param adjustments the list of adjustments to search for changes
 * @param defaultPatch the default value to fall back to if no adjustment change is found
 * @returns `string` the id of the patch
 */
const determinePatch = (
  adjustments: Adjustment[],
  defaultPatch: PatchModel
): string => {
  const change = getAdjustmentChange(adjustments, AdjustmentType.Patch);
  // need to compare field again here so typescript can infer the type
  return change?.field === AdjustmentType.Patch
    ? change.change
    : defaultPatch.id;
};

/**
 * finds the first skills change in a list of adjustments and returns it, or falls back to the default value if none found
 *
 * @param adjustments the list of adjustments to search for changes
 * @param defaultSkills the default value to fall back to if no adjustment change is found
 * @returns `string[]` of the id of the skills
 */
const determineSkills = (
  adjustments: Adjustment[],
  defaultSkillsIds: string[]
) => {
  const change = getAdjustmentChange(adjustments, AdjustmentType.Skills);

  return change?.field === AdjustmentType.Skills
    ? change.change
    : defaultSkillsIds;
};

/**
 * finds the first efficiency change in a list of adjustments and returns it, or falls back to the default value if none found
 *
 * @param adjustments the list of adjustments to search for changes
 * @param defaultPatch the default value to fall back to if no adjustment change is found
 * @returns `number` the detemined efficiency
 */
const determineEfficiency = (
  adjustments: Adjustment[],
  defaultEfficiency: number
) => {
  const change = getAdjustmentChange(adjustments, AdjustmentType.Efficiency);
  // need to compare field again here so typescript can infer the type
  return change?.field === AdjustmentType.Efficiency
    ? change.change
    : defaultEfficiency;
};

/**
 * finds the first shift pattern change in a list of adjustments and returns it, or falls back to the default value if none found
 *
 * @param adjustments the list of adjustments to search for changes
 * @param defaultPatch the default value to fall back to if no adjustment change is found
 * @returns `ShiftPattern` the determined shift pattern
 */
const determineShiftPattern = (
  adjustments: Adjustment[],
  defaultShiftPattern: ShiftPattern
) => {
  const change = getAdjustmentChange(adjustments, AdjustmentType.Shift);
  // need to compare field again here so typescript can infer the type
  return change?.field === AdjustmentType.Shift
    ? change.change
    : defaultShiftPattern;
};

/**
 * Finds the first max travel time change in a list of adjustments and returns it, or falls back to the default value if none found
 *
 * @param adjustments the list of adjustments to search for changes
 * @param defaultMaxTravelTime the default value to fall back to if no adjustment change is found
 * @returns `number` the determined max travel time
 */
const determineMaxTravelTime = (
  adjustments: Adjustment[],
  defaultMaxTravelTime: number
) => {
  const adjustmentChange = getAdjustmentChange(
    adjustments,
    AdjustmentType.MaxTravel
  );
  // need to compare field again here so typescript can infer the type
  return adjustmentChange?.field === AdjustmentType.MaxTravel
    ? adjustmentChange.change
    : defaultMaxTravelTime;
};

/**
 * filter a list of adjustments by a specific date by matching 'startDate' / 'endDate' and the adjustments 'pattern'
 * @param adjustments adjustments to filter
 * @param date date to filter by
 * @returns filtered list of adjustments
 */
export const filterAdjustmentsByDate = (
  adjustments: Adjustment[],
  date: DateTime
) =>
  adjustments
    .filter(
      adjustment =>
        DateTime.fromISO(adjustment.startDate) <= date &&
        DateTime.fromISO(adjustment.endDate) >= date
    )
    .filter(adjustment => {
      const dayOfweek = dateToDayOfWeek(date);
      return doesApplyToDay(adjustment, dayOfweek);
    });

/**
 * Create an engineer summary for a given date
 * @param date the date to generate a summary for
 * @param adjustments all the adjustments to apply to the engineer
 * @param engineer the engineer to generate the summary for
 * @returns `EngineerCalendarAdjustmentSummary` the engineer summary
 */
const summaryForDate = (
  date: DateTime,
  adjustments: Adjustment[],
  engineer: Engineer
): EngineerCalendarAdjustmentsSummary => {
  const applicableAdjustments = filterAdjustmentsByDate(adjustments, date);
  const shiftPattern = determineShiftPattern(
    applicableAdjustments,
    engineer.defaultShiftPattern
  );
  const shiftPatternDay = getShiftPatternDayForDate(shiftPattern, date);
  const skillsToSkillIds = engineer.skills.map(skill => skill.id);
  return {
    date,
    patch: determinePatch(applicableAdjustments, engineer.defaultPatch),
    efficiency: determineEfficiency(
      applicableAdjustments,
      engineer.defaultEfficiency
    ),
    maxTravel: determineMaxTravelTime(
      applicableAdjustments,
      engineer.maxTravelTime
    ),
    skills: determineSkills(applicableAdjustments, skillsToSkillIds),
    startTime: shiftPatternDay?.startTime,
    endTime: shiftPatternDay?.endTime,
    isWorking: shiftPatternDay != null,
    adjustments: applicableAdjustments.filter(
      adjustment =>
        adjustment.priority == undefined ||
        adjustment.priority === AdjustmentPriority.Temporary
    )
  };
};

/**
 * get the engineer summaries for an engineer for each day in a given time interval
 * @param adjustments the adjustment to apply
 * @param interval the time interval
 * @param engineer the engineer to generate summaries for
 * @returns a list of summaries
 */
export const getEngineerSummariesForInterval = (
  adjustments: Adjustment[],
  interval: Interval,
  engineer: Engineer
): EngineerCalendarAdjustmentsSummary[] =>
  intervalToArrayByDay(interval).map(date =>
    summaryForDate(date, adjustments, engineer)
  );
