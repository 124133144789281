import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import Typography from 'components/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Interval } from 'luxon';
import { AdjustmentType } from 'models/Adjustment';
import React, { useContext } from 'react';
import {
  useAdjustmentsState,
  usePatchesData,
  useSkillsData
} from 'store/selectors';
import { TemporaryAdjustmentFormContext } from '../EngineerSchedule/AdjustmentFormModals';
import {
  EngineerCalendarContext,
  EngineerCalendarDatesContext
} from './EngineerCalendar';
import {
  CalendarSpan,
  CalendarSpanType,
  computeCalendarSpanWidth,
  computeWorkWeekWidth,
  getFilteredSummaries,
  summariesToSpans
} from './engineerCalendarDefaultsHelper';

interface EngineerCalendarDefaultsProps {
  cellWidth: number;
}
interface StylesProps {
  workWeekWidth: number;
}

const defaultCalendarSpanGap = 3;

export const useEngineerCalendarDefaultsStyles = makeStyles<Theme, StylesProps>(
  theme => {
    return {
      defaultsContainer: ({ workWeekWidth }) => ({
        display: 'flex',
        position: 'absolute',
        left: 1,
        zIndex: 2,
        gap: theme.spacing(1),
        flexDirection: 'column',
        width: workWeekWidth
      }),
      defaultSchedulePattern: {
        display: 'flex',
        flexDirection: 'row',
        gap: defaultCalendarSpanGap
      }
    };
  }
);

interface PeriodSpanProps {
  span: CalendarSpan;
  width: number;
  onEdit: (span: CalendarSpan) => void;
}

export const usePeriodSpanStyles = makeStyles<
  Theme,
  {
    width: number;
    changeType: string | null;
  }
>(
  theme => {
    return {
      tooltip: {
        background: theme.palette.aegis.semantic.message.black,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1)
      },
      tooltipTypography: {
        color: theme.palette.aegis.semantic.message.white
      },
      tooltipDateWrapper: ({ changeType }) => ({
        marginBottom: changeType === 'skills' ? theme.spacing(1) : 'initial'
      }),
      span: ({ width }) => ({
        display: 'flex',
        minWidth: width,
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.aegis.colour.grey.lighter,
        '&.shiftPatternAdjustment, &.shiftPatternAdjustmentAbsent, &.groupedPatchAdjustment, &.groupedEfficiencyAdjustment': {
          cursor: 'pointer'
        },
        '&.shiftPatternAdjustment, &.groupedPatchAdjustment, &.groupedEfficiencyAdjustment': {
          backgroundColor: theme.palette.aegis.colour.blue.lighter
        },
        '&.shiftPatternAdjustmentAbsent': {
          backgroundColor: theme.palette.aegis.colour.grey.darker
        },
        '&.patchAdjustment': {
          backgroundColor: theme.palette.aegis.colour.purple.lightest,
          cursor: 'pointer'
        },
        '&.efficiencyAdjustment': {
          backgroundColor: theme.palette.aegis.colour.orange.lighter,
          cursor: 'pointer'
        },
        '&.skillsAdjustment': {
          backgroundColor: theme.palette.aegis.colour.green.lighter,
          cursor: 'pointer'
        },
        '&.maxTravelAdjustment': {
          backgroundColor: theme.palette.aegis.colour.green.lighter,
          cursor: 'pointer'
        }
      }),
      spanTypography: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&.shiftPatternAdjustment, &.groupedPatchAdjustment, &.groupedEfficiencyAdjustment': {
          color: theme.palette.aegis.colour.blue.darkest
        },
        '&.shiftPatternAdjustmentAbsent': {
          color: theme.palette.aegis.semantic.message.white
        },
        '&.patchAdjustment': {
          color: theme.palette.aegis.colour.purple.darkest
        },
        '&.efficiencyAdjustment': {
          color: theme.palette.aegis.colour.orange.darkest
        },
        '&.skillsAdjustment': {
          color: theme.palette.aegis.colour.grey.darkest,
          cursor: 'pointer'
        }
      }
    };
  },
  { name: 'EngineerCalendarDefaults' }
);

export const PeriodSpan = ({ span, width, onEdit }: PeriodSpanProps) => {
  const changeType = {
    [CalendarSpanType.ShiftPatternAdjustment]: 'shift',
    [CalendarSpanType.ShiftPatternAdjustmentAbsent]: 'shift',
    [CalendarSpanType.PatchAdjustment]: 'patch',
    [CalendarSpanType.GroupedPatchAdjustment]: 'patch',
    [CalendarSpanType.EfficiencyAdjustment]: 'efficiency',
    [CalendarSpanType.GroupedEfficiencyAdjustment]: 'efficiency',
    [CalendarSpanType.SkillsAdjustment]: 'skills',
    [CalendarSpanType.MaxTravel]: 'maxTravel',
    [CalendarSpanType.DefaultValue]: null
  }[span.type];

  const classes = usePeriodSpanStyles({
    width,
    changeType
  });
  const handleClick = () => {
    onEdit(span);
  };

  const tooltipContent = (
    localChangeType: string | null,
    localSpan: CalendarSpan
  ) => {
    if (localChangeType === null) return '';

    return (
      <Typography
        component="div"
        variant="bodyHeavy"
        className={classes.tooltipTypography}
      >
        <div>
          Temporary {changeType === 'maxTravel' ? 'travel' : changeType} change
        </div>
        <div className={classes.tooltipDateWrapper}>
          <div>
            From: {localSpan?.adjustmentStartDay?.toFormat('ccc dd LLL yyyy')}
          </div>

          <div>
            To: {localSpan?.adjustmentEndDay?.toFormat('ccc dd LLL yyyy')}
          </div>
        </div>
        {changeType === 'skills' && (
          <>
            <div>Skills:</div>
            <div>{localSpan?.value}</div>
          </>
        )}
      </Typography>
    );
  };

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={tooltipContent(changeType, span)}
      placement="bottom-start"
    >
      <div
        className={`${classes.span} ${span.type}`}
        data-name="period-span"
        onClick={handleClick}
      >
        <Typography
          variant="bodyHeavy"
          className={`${classes.spanTypography} ${span.type}`}
        >
          {span.value}
        </Typography>
      </div>
    </Tooltip>
  );
};

export const EngineerCalendarDefaults = ({
  cellWidth
}: EngineerCalendarDefaultsProps) => {
  const { engineerData } = useContext(EngineerCalendarContext);
  const { dates } = useContext(EngineerCalendarDatesContext);

  const {
    setIsOpen: setEditScheduleModalOpen,
    setAdjustment: setEditScheduleModalAdjustment
  } = useContext(TemporaryAdjustmentFormContext);

  const calendarInterval = Interval.fromDateTimes(dates.start, dates.end);

  const adjustments = useAdjustmentsState().items;

  const patches = usePatchesData();
  const skills = useSkillsData();

  const flags = useFlags();

  const summaries =
    engineerData !== undefined && adjustments.length > 0
      ? getFilteredSummaries(adjustments, calendarInterval, engineerData)
      : [];

  const shiftPatternSpans = summariesToSpans(
    summaries,
    AdjustmentType.Shift,
    undefined
  );
  const patchSpans = summariesToSpans(summaries, AdjustmentType.Patch, patches);
  const efficiencySpans = summariesToSpans(
    summaries,
    AdjustmentType.Efficiency,
    undefined
  );

  const skillsSpans = summariesToSpans(
    summaries,
    AdjustmentType.Skills,
    undefined,
    skills
  );

  const maxTravelSpans = summariesToSpans(
    summaries,
    AdjustmentType.MaxTravel,
    undefined
  );

  const workWeekWidth = computeWorkWeekWidth(
    shiftPatternSpans,
    cellWidth,
    defaultCalendarSpanGap
  );

  const classes = useEngineerCalendarDefaultsStyles({ workWeekWidth });

  const canEditType = (type: AdjustmentType) => {
    switch (type) {
      case AdjustmentType.Shift:
        return true;

      case AdjustmentType.Patch:
        return true;

      case AdjustmentType.Efficiency:
        return true;

      case AdjustmentType.Skills:
        return true;

      case AdjustmentType.MaxTravel:
        return true;

      default:
        return false;
    }
  };

  const onEditAdjustmentForSpan = (span: CalendarSpan) => {
    if (span.adjustmentType && !canEditType(span.adjustmentType)) {
      return;
    }

    const adjustmentToEdit = summaries
      .find(summary => summary.date.equals(span.spanStartDay))
      ?.adjustments?.find(adjustment =>
        adjustment.changes.some(change => change.field === span.adjustmentType)
      );

    if (adjustmentToEdit != undefined) {
      setEditScheduleModalAdjustment?.(adjustmentToEdit);
      setEditScheduleModalOpen?.(true);
    }
  };

  const onEdit = (span: CalendarSpan) => {
    onEditAdjustmentForSpan(span);
  };

  return (
    <div
      className={classes.defaultsContainer}
      data-testid="engineerCalendarDefaultAdjustmentValues"
    >
      <div
        className={classes.defaultSchedulePattern}
        data-testid="engineerCalendarShiftPattern"
      >
        {shiftPatternSpans.map(span => (
          <PeriodSpan
            width={computeCalendarSpanWidth(
              span,
              cellWidth,
              defaultCalendarSpanGap
            )}
            span={span}
            onEdit={onEditAdjustmentForSpan}
            key={span.spanStartDay.weekday}
          />
        ))}
      </div>
      <div
        className={classes.defaultSchedulePattern}
        data-testid="engineerCalendarPatch"
      >
        {patchSpans.map(span => (
          <PeriodSpan
            width={computeCalendarSpanWidth(
              span,
              cellWidth,
              defaultCalendarSpanGap
            )}
            span={span}
            onEdit={onEditAdjustmentForSpan}
            key={span.spanStartDay.weekday}
          />
        ))}
      </div>
      <div
        className={classes.defaultSchedulePattern}
        data-testid="engineerCalendarEfficiency"
      >
        {efficiencySpans.map(span => (
          <PeriodSpan
            width={computeCalendarSpanWidth(
              span,
              cellWidth,
              defaultCalendarSpanGap
            )}
            span={span}
            onEdit={onEdit}
            key={span.spanStartDay.weekday}
          />
        ))}
      </div>
      <div
        className={classes.defaultSchedulePattern}
        data-testid="engineerCalendarSkills"
      >
        {skillsSpans.map(span => (
          <PeriodSpan
            width={computeCalendarSpanWidth(
              span,
              cellWidth,
              defaultCalendarSpanGap
            )}
            span={span}
            onEdit={onEdit}
            key={span.spanStartDay.weekday}
          />
        ))}
      </div>
      <div
        className={classes.defaultSchedulePattern}
        data-testid="engineerCalendarMaxTravel"
      >
        {flags?.aegisEnableMaxTravelTimesDto &&
          maxTravelSpans.map(span => (
            <PeriodSpan
              width={computeCalendarSpanWidth(
                span,
                cellWidth,
                defaultCalendarSpanGap
              )}
              span={span}
              onEdit={onEdit}
              key={span.spanStartDay.weekday}
            />
          ))}
      </div>
    </div>
  );
};
