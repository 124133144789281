/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import {
  StateSlice,
  startLoading,
  loadingFailed,
  initialStateSliceWithoutPagination
} from 'store/slices';
import {
  appointmentDomain,
  engineerAssignedToJobDomain,
  engineerDomain,
  jobDetailsDomain,
  jobDomain,
  jobTypeDomain
} from 'models';
import { createUrl, handleThunkError } from 'store/utils';
import { AppointmentResponse, AppointmentStatus } from 'models/Appointment';
import http from 'api/http';
import Engineer, { EngineersAssigned } from 'models/Engineers';
import JobType from 'models/JobTypes';
import { JobDetailsResponse, JobEnrichedResponse } from 'models/JobDetails';
import { fetchAuditData } from 'store/endpoints/audit';
import { fetchPreviousAborts } from 'store/endpoints/appointment';
import { fetchPsrDetails } from 'store/endpoints/psr';

const jobsDetails = createSlice({
  name: jobDetailsDomain.plural,
  initialState: initialStateSliceWithoutPagination as StateSlice<
    JobDetailsResponse
  >,
  reducers: {
    ...startLoading<JobDetailsResponse>(),
    ...loadingFailed<JobDetailsResponse>(),
    // TODO: Abstract these out to be generic functions
    getJobDetailsSuccess(
      state,
      { payload: jobDetailsItem }: PayloadAction<JobDetailsResponse>
    ) {
      if (state.items != null) {
        state.items = state.items.filter(
          jobDetails => jobDetails.id !== jobDetailsItem.id
        );
        state.items.push(jobDetailsItem);
      }
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  requestStart,
  requestFail,
  getJobDetailsSuccess
} = jobsDetails.actions;
export default jobsDetails.reducer;

async function fetchAssignedEngineer(
  engineersData?: EngineersAssigned
): Promise<Engineer | null> {
  if (engineersData?.engineers.length) {
    const engineerId = engineersData.engineers[0];
    const { data: engineerData } = await http.get<Engineer>(
      createUrl(engineerDomain, null, engineerId)
    );
    return engineerData;
  }
  return null;
}

async function fetchEnrichedJobData(
  appointmentData: AppointmentResponse
): Promise<JobEnrichedResponse | null> {
  if (
    appointmentData.status === AppointmentStatus.COMPLETED ||
    appointmentData.status === AppointmentStatus.ABORTED ||
    appointmentData.status === AppointmentStatus.CANCELLED
  ) {
    try {
      const { data: enrichedJobData } = await http.get<JobEnrichedResponse>(
        `${jobDomain.apiPath}/${appointmentData.id}/enriched`,
        { headers: { 'X-New-Data-Structure': true } }
      );

      return enrichedJobData;
    } catch (error) {
      // Scheduler aborted jobs might not have workflow data - expected behaviour
      if (error?.response?.status !== 404) {
        throw error;
      }
    }
  }
  return null;
}

async function fetchJobTypeData(
  appointmentData: AppointmentResponse
): Promise<JobType> {
  const jobTypeId = appointmentData.jobType;
  const { data: jobTypeData } = await http.get<JobType>(
    createUrl(jobTypeDomain, null, jobTypeId)
  );
  return jobTypeData;
}

export function fetchJobsDetails(
  id: string,
  enableDisplayPsrVulnerabilities?: boolean
): AppThunk {
  return async dispatch => {
    try {
      dispatch(requestStart());

      const { data: appointmentData } = await http.get<AppointmentResponse>(
        createUrl(appointmentDomain, null, id)
      );

      let engineerData;
      try {
        engineerData = await http.get<EngineersAssigned>(
          createUrl(engineerAssignedToJobDomain, id)
        );
      } catch (error) {
        // If the response status is 404 we want to display the job as unassigned
        if (error?.response?.status === 404) {
          engineerData = null;
        } else {
          handleThunkError({
            error,
            requestFail,
            dispatch,
            domainType: engineerAssignedToJobDomain.type
          });
        }
      }

      const [
        jobTypeData,
        assignedEngineer,
        enrichedJob,
        auditEntries,
        previousAbortData,
        psrDetails
      ] = await Promise.all([
        fetchJobTypeData(appointmentData),
        fetchAssignedEngineer(engineerData?.data),
        fetchEnrichedJobData(appointmentData),
        fetchAuditData(appointmentData.id),
        fetchPreviousAborts(appointmentData),
        fetchPsrDetails(
          appointmentData.accountNumber,
          enableDisplayPsrVulnerabilities
        )
      ]);

      const jobDetails = {
        ...appointmentData,
        assignedEngineer,
        jobTypeData,
        enrichedJob,
        auditEntries,
        previousAbortData,
        psrDetails
      };

      dispatch(getJobDetailsSuccess(jobDetails));

      return jobDetails;
    } catch (error) {
      handleThunkError({
        error,
        requestFail,
        dispatch,
        domainType: jobDetailsDomain.type
      });

      return null;
    }
  };
}
