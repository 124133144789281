import { InputFieldProps } from 'components/Forms';
import { AdjustmentType, DayOfWeek } from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';
import { getAdjustmentChange, getAdjustmentForDay } from 'utils/adjustments';
import { efficiencyReasonOptions } from 'views/Engineers/Adjustments/AdjustmentFormConfig';
import {
  TemporaryAdjustmentConstants,
  atLeastOneFilledSchema,
  getEfficiencyOptions
} from 'views/Engineers/utils';
import * as Yup from 'yup';
import {
  adjustmentCommonFieldsInitialValues,
  adjustmentCommonFieldsValidationSchema,
  adjustmentWeekdayKeys,
  getAdjustmentCommonFields
} from './adjustmentFormCommonConfig';

export const getDayFieldName = (day: string) => `efficiencyChange${day}`;

const getEfficiencyFieldForWeekday = (day: string): InputFieldProps => {
  const fieldName = getDayFieldName(day);
  return {
    id: fieldName,
    name: fieldName,
    qaId: fieldName,
    disablePlaceholder: true,
    inputType: 'select',
    options: getEfficiencyOptions()
  };
};

export const efficiencyFormCommonFields = getAdjustmentCommonFields(
  efficiencyReasonOptions()
);

export const efficiencyFormWeekdayFields = Object.fromEntries(
  adjustmentWeekdayKeys.map(day => [day, getEfficiencyFieldForWeekday(day)])
);

export const efficiencyFormValidationSchema = Yup.object()
  .shape(adjustmentCommonFieldsValidationSchema)
  .test('atLeastOneFilled', function validOneShiftPattern(formValues) {
    return atLeastOneFilledSchema(
      formValues,
      getDayFieldName,
      this.createError
    );
  });

const getWeekdayEfficiencyFromGroup = (
  day: DayOfWeek,
  adjustmentGroup: AdjustmentGroup
) => {
  const adjustment = getAdjustmentForDay(day, adjustmentGroup.adjustments);
  return adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.Efficiency)?.change
    : undefined;
};

export const efficiencyFormWeekdaysInitialValues = (
  adjustmentGroup: AdjustmentGroup | undefined
) => {
  const fieldNameEfficiencyPairs = adjustmentWeekdayKeys.map(day => {
    const adjustmentGroupEfficiency = adjustmentGroup
      ? getWeekdayEfficiencyFromGroup(
          day.toUpperCase() as DayOfWeek,
          adjustmentGroup
        )
      : null;

    return [
      getDayFieldName(day),
      adjustmentGroupEfficiency || TemporaryAdjustmentConstants.Empty
    ];
  });

  return {
    ...Object.fromEntries(fieldNameEfficiencyPairs)
  };
};

export const efficiencyFormInitialValues = (
  adjustmentGroup?: AdjustmentGroup
) => {
  const efficiencyDaysInitialValues = efficiencyFormWeekdaysInitialValues(
    adjustmentGroup
  );

  return {
    ...adjustmentCommonFieldsInitialValues(adjustmentGroup?.adjustments[0]),
    ...efficiencyDaysInitialValues
  };
};
