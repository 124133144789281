import GenericDataType from './GenericDataType';
import Patch from './Patches';
import ShiftPattern from './ShiftPattern';
import Skill from './Skills';
import Team from './Team';
import Adjustment from './Adjustment';

export default interface Engineer extends GenericDataType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress?: string;
  postcode: string;
  team?: Team;
  skills: Skill[];
  vehicleType: string;
  maxTravelTime: number;
  company: string;
  byBox: string;
  defaultPatch: Patch;
  defaultShiftPattern: ShiftPattern;
  defaultEfficiency: number;
  adjustments?: Adjustment[];
}

export interface EngineerSummary {
  engineerId: string;
  date: string;
  startTime: string;
  endTime: string;
  jobTypes: string[];
  perDayEfficiency: number;
  patch: string;
  postcode: string;
  maxTravelTime: number;
}

export interface EngineersAssigned {
  engineers: string[];
}

export enum VehicleTypes {
  Diesel = 'DIESEL',
  DieselRural = 'DIESEL_RURAL',
  DieselExtraRural = 'DIESEL_EXTRA_RURAL',
  DieselUrban = 'DIESEL_URBAN',
  DieselExtraUrban = 'DIESEL_EXTRA_URBAN',
  EV = 'EV',
  EVRural = 'EV_RURAL',
  EVExtraRural = 'EV_EXTRA_RURAL',
  EVUrban = 'EV_URBAN',
  EVExtraUrban = 'EV_EXTRA_URBAN',
  Hybrid = 'HYBRID',
  None = '',
  Petrol = 'PETROL'
}

export interface JobMovementEngineerInfo {
  id: string;
  firstName: string;
  lastName: string;
}
