import { Domain } from 'models/Domain';

export const appointmentDomain: Domain = {
  type: 'appointment',
  apiPath: 'appointment',
  urlPath: 'appointments',
  singular: 'appointment',
  plural: 'appointments',
  label: 'Appointment',
  stateful: true
};

export const psrDomain: Domain = {
  type: 'psr',
  apiPath: 'psr',
  urlPath: 'psr',
  singular: 'psr',
  plural: 'psrs',
  label: 'PSR',
  stateful: true
};

export const engineerDomain: Domain = {
  type: 'engineer',
  apiPath: 'engineer',
  urlPath: 'engineers',
  singular: 'engineer',
  plural: 'engineers',
  label: 'Engineers',
  stateful: true
};

export const engineerSummaryDomain: Domain = {
  type: 'engineerSummary',
  apiPath: `${engineerDomain.apiPath}/summary/find`,
  urlPath: 'engineer',
  singular: 'engineer summary',
  plural: 'engineerSummaries',
  label: 'Engineer Summary',
  stateful: false
};

export const jobTypeDomain: Domain = {
  type: 'jobType',
  apiPath: 'job/type',
  urlPath: 'job-types',
  singular: 'job type',
  plural: 'jobTypes',
  label: 'Job Types',
  stateful: true
};

export const patchDomain: Domain = {
  type: 'patch',
  apiPath: 'patch',
  urlPath: 'patches',
  singular: 'patch',
  plural: 'patches',
  label: 'Patches',
  stateful: true
};

export const shiftPatternDomain: Domain = {
  type: 'shiftPattern',
  apiPath: `${engineerDomain.apiPath}/shift-pattern`,
  urlPath: 'shift-patterns',
  singular: 'shift pattern',
  plural: 'shiftPatterns',
  label: 'Shift Patterns',
  stateful: true
};

export const skillDomain: Domain = {
  type: 'skill',
  apiPath: 'skill',
  urlPath: 'skills',
  singular: 'skill',
  plural: 'skills',
  label: 'Skills',
  stateful: true
};

export const skillCategoryDomain: Domain = {
  type: 'skillCategory',
  apiPath: `${skillDomain.apiPath}/category`,
  urlPath: 'skill-categories',
  singular: 'skill category',
  plural: 'skillCategories',
  label: 'Skill Categories',
  stateful: true
};

export const teamDomain: Domain = {
  type: 'team',
  apiPath: `${engineerDomain.apiPath}/team`,
  urlPath: 'teams',
  singular: 'team',
  plural: 'teams',
  label: 'Teams',
  stateful: true
};

export const timeBandDomain: Domain = {
  type: 'timeBand',
  apiPath: 'timeband',
  urlPath: 'timeband',
  singular: 'timeband',
  plural: 'timeBands',
  label: 'Time Bands',
  stateful: true
};

export const adjustmentDomain: Domain = {
  type: 'adjustment',
  apiPath: 'adjustment',
  urlPath: 'adjustment',
  singular: 'adjustment',
  plural: 'adjustments',
  label: 'Adjustments',
  parentDomain: engineerDomain,
  stateful: false
};

export const adjustmentGroupDomain: Domain = {
  type: 'adjustmentGroup',
  apiPath: 'adjustment-group',
  urlPath: 'adjustment-group',
  singular: 'adjustmentGroup',
  plural: 'adjustmentGroups',
  label: 'Adjustment Groups',
  parentDomain: engineerDomain,
  stateful: true
};

export const scheduleDomain: Domain = {
  type: 'schedule',
  apiPath: 'schedule',
  urlPath: 'schedule',
  singular: 'schedule',
  plural: 'schedules',
  label: 'Schedules',
  stateful: true
};

export const assignmentDomain: Domain = {
  type: 'assignment',
  apiPath: 'assignment',
  urlPath: 'assignment',
  singular: 'assignment',
  plural: 'assignment',
  label: 'Assignment',
  parentDomain: scheduleDomain,
  fullPath: `${scheduleDomain.apiPath}/assignment`,
  stateful: false
};

export const engineerAssignedToJobDomain: Domain = {
  type: 'engineer',
  apiPath: 'engineer',
  urlPath: 'engineers',
  singular: 'engineer',
  plural: 'engineers',
  label: 'Engineers',
  parentDomain: assignmentDomain,
  stateful: true
};

export const geographicAreaDomain: Domain = {
  type: 'geographicArea',
  apiPath: 'geographic-area',
  urlPath: 'geographic-area',
  singular: 'geographic area',
  plural: 'geographicAreas',
  label: 'Geographic Area',
  stateful: true
};

export const jobDomain: Domain = {
  type: 'job',
  apiPath: 'job',
  urlPath: 'jobs',
  singular: 'job',
  plural: 'jobs',
  label: 'Jobs',
  stateful: false
};

export const jobDetailsDomain: Domain = {
  type: 'jobDetails',
  apiPath: 'jobs',
  urlPath: 'jobs',
  singular: 'job details',
  plural: 'jobsDetails',
  label: 'Job Details',
  stateful: true
};

export const workflowDomain: Domain = {
  type: 'workflow',
  apiPath: 'workflow',
  urlPath: 'workflow',
  singular: 'workflow',
  plural: 'workflows',
  label: 'Workflow',
  stateful: false
};

export const capacityDomain: Domain = {
  type: 'capacity',
  apiPath: 'capacity',
  urlPath: 'capacity',
  singular: 'capacity',
  plural: 'capacity',
  label: 'Capacity',
  stateful: false
};

export const capacityUtilisationDomain: Domain = {
  type: 'capacityUtilisation',
  apiPath: 'capacity/utilisation',
  urlPath: 'capacity',
  singular: 'capacity utilisation',
  plural: 'capacityUtilisation',
  label: 'Capacity',
  stateful: false
};

export const abortCategoriesDomain: Domain = {
  type: 'abortCategories',
  apiPath: 'abort-category',
  urlPath: 'abort-category',
  singular: 'abort category',
  plural: 'abortCategories',
  label: 'Abort Categories',
  stateful: true
};

export const regionsDomain: Domain = {
  type: 'regions',
  apiPath: 'geographic-area-category',
  urlPath: 'regions',
  singular: 'region',
  plural: 'regions',
  label: 'Region',
  stateful: false
};
