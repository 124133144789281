/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formRef"] }] */

import { IconButton, makeStyles } from '@material-ui/core';
import Button from 'components/Button';
import {
  Field,
  Form,
  FormValues,
  yupSchemaToValidationFunc
} from 'components/Forms';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormApiWithRestart } from 'components/Forms/Form';
import TimeTableSelect from 'components/Forms/TimetableSelect';
import Modal, { useModalStyles } from 'components/Modal';
import ErrorDisplay from 'components/ErrorDisplay';
import Typography from 'components/Typography';
import { DateTime, Interval } from 'luxon';
import Adjustment, {
  AdjustmentPriority,
  AdjustmentType,
  AdjustmentChangeType,
  Pattern
} from 'models/Adjustment';
import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAdjustmentsState } from 'store/selectors';
import {
  adjustmentTypeFilter,
  getAdjustmentForDay,
  getAdjustmentGroup
} from 'utils/adjustments';
import { dateToDayOfWeek, getFirstWeekAsDateArray } from 'utils/dates';
import { EngineerCalendarContext } from 'views/Engineers/EngineerCalendar/EngineerCalendar';
import { adjustmentGroupsSlice } from 'views/Engineers/store';
import { TemporaryAdjustmentConstants } from 'views/Engineers/utils';
import { FormSpy } from 'react-final-form';
import {
  AdjustmentFormButtonActions,
  TemporaryAdjustmentFormContext,
  TemporaryAdjustmentFormModalProps
} from '../AdjustmentFormModals';
import { AdjustmentFormCommonFieldName } from './adjustmentFormCommonConfig';

import {
  maxTravelFormCommonFields,
  maxTravelFormInitialValues,
  maxTravelFormValidationSchema,
  maxTravelFormWeekdayFields,
  getDayFieldName,
  maxTravelOptions
} from './maxTravelChangeFormConfig';
import { useAdjustmentFormStyles } from './FormStyles';
import { formSpyRelevantDays, useFormDaysOfWeek } from './useFormDaysOfWeek';

const useStyles = makeStyles(
  {
    previousButton: {
      marginRight: 'auto'
    }
  },
  { name: 'TemporaryMaxTravelChangeModal' }
);

function TemporaryMaxTravelFormModalButtons({
  onPrevious,
  onCancel,
  onSubmit,
  showPrevious
}: AdjustmentFormButtonActions) {
  const classes = useStyles();

  return (
    <>
      {showPrevious ? (
        <Button
          key="maxTravelChangeModalPreviousButton"
          color="secondary"
          size="small"
          onClick={onPrevious}
          data-testid="maxTravelChangeModalPreviousButton"
          className={classes.previousButton}
        >
          Previous
        </Button>
      ) : null}

      <Button
        key="maxTravelChangeModalCancelButton"
        color="secondary"
        size="small"
        onClick={onCancel}
        data-testid="maxTravelChangeModalCancelButton"
      >
        Cancel
      </Button>

      <Button
        key="maxTravelChangeModalSubmitButton"
        color="primary"
        size="small"
        onClick={onSubmit}
        data-testid="maxTravelChangeModalSubmitButton"
      >
        Save
      </Button>
    </>
  );
}

export default function TemporaryMaxTravelAdjustmentModal({
  onPrevious,
  onDelete
}: TemporaryAdjustmentFormModalProps) {
  const [formDates, setValueFormSpy, formDaysOfWeek] = useFormDaysOfWeek();
  const { engineerData } = useContext(EngineerCalendarContext);
  const { isOpen, setIsOpen, adjustment } = useContext(
    TemporaryAdjustmentFormContext
  );
  const dispatch = useDispatch();
  const classes = useAdjustmentFormStyles();
  const modalClasses = useModalStyles();
  const formRef = useRef<FormApiWithRestart>();

  const handleSubmitButtonClick = () => {
    formRef.current?.submit();
  };

  const handleClose = () => {
    setIsOpen?.(false);
  };

  const adjustmentsState = useAdjustmentsState();
  const maxTravelAdjustments = adjustmentsState.items.filter(
    adjustmentTypeFilter(AdjustmentType.MaxTravel)
  );

  const adjustmentGroup = adjustment?.groupId
    ? getAdjustmentGroup(maxTravelAdjustments, adjustment.groupId)
    : undefined;

  const maxTravelFormSubmitHandler = (fieldValues: FormValues) => {
    const startDate = DateTime.fromISO(
      fieldValues[AdjustmentFormCommonFieldName.ValidFrom] as string
    );
    const endDate = DateTime.fromISO(
      fieldValues[AdjustmentFormCommonFieldName.ValidTo] as string
    );

    const dates = getFirstWeekAsDateArray(
      Interval.fromDateTimes(startDate, endDate)
    );

    const adjustments = dates.reduce((acc: Adjustment[], date) => {
      const day = dateToDayOfWeek(date);

      const adjustmentForDay = getAdjustmentForDay(
        day,
        adjustmentGroup?.adjustments ?? []
      );

      const fieldValuesFormat = date.weekdayLong;
      const currentValue = fieldValues[getDayFieldName(fieldValuesFormat)];

      const optionalId = adjustmentForDay ? { id: adjustmentForDay.id } : {};
      const optionalGroupId = adjustment ? { groupId: adjustment.groupId } : {};

      if (currentValue !== TemporaryAdjustmentConstants.Empty) {
        const adjustmentObj: Adjustment = {
          ...(optionalId as { id: string }),
          ...optionalGroupId,
          reason: fieldValues[AdjustmentFormCommonFieldName.Reason] as string,
          startDate: startDate.toISODate(),
          endDate: endDate.toISODate(),
          pattern: {
            type: Pattern.Weekly,
            weekdays: [day]
          },
          changes: [
            {
              field: AdjustmentType.MaxTravel,
              change: fieldValues[getDayFieldName(date.weekdayLong)]
            }
          ] as AdjustmentChangeType[],
          priority: AdjustmentPriority.Temporary
        };

        return [...acc, adjustmentObj];
      }

      return acc;
    }, [] as Adjustment[]);
    const adjustmentsGroupToSave = { adjustments };

    dispatch(
      adjustment
        ? adjustmentGroupsSlice.actions.edit(
            adjustmentsGroupToSave,
            adjustment.groupId,
            engineerData?.id
          )
        : adjustmentGroupsSlice.actions.add(
            adjustmentsGroupToSave,
            engineerData?.id
          )
    );

    handleClose();
  };

  return (
    <Modal
      title="Max Travel Time Change"
      testId="maxTravelAdjustmentFormModal"
      open={isOpen || false}
      onClose={handleClose}
      modalActions={
        <TemporaryMaxTravelFormModalButtons
          onCancel={handleClose}
          onSubmit={handleSubmitButtonClick}
          onPrevious={onPrevious}
          showPrevious
        />
      }
      additionalButtons={
        adjustment ? (
          <IconButton
            aria-label="delete"
            onClick={onDelete}
            className={modalClasses.button}
            data-testid="deleteMaxTravelAdjustmentIconButton"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
      }
    >
      <Form
        initialValues={maxTravelFormInitialValues(adjustmentGroup)}
        validationFunc={yupSchemaToValidationFunc(
          maxTravelFormValidationSchema
        )}
        onSubmit={maxTravelFormSubmitHandler}
        goBackToPreviousPage={false}
      >
        {({ form }) => {
          formRef.current = form;
          const formState = form.getState();
          return (
            <>
              {maxTravelFormCommonFields.map(field => (
                <Field key={field.id} field={field} />
              ))}
              <Typography variant="h2" className={classes.typography}>
                New Max Travel Time
              </Typography>
              {formState.submitFailed && formState.errors?.atLeastOneFilled ? (
                <div className={classes.allEmptyErrorContainer}>
                  <ErrorDisplay
                    status={{
                      message: formState.errors?.atLeastOneFilled
                    }}
                  />
                </div>
              ) : null}
              <div data-testid="maxTravelWeekdaySelectsWrapper">
                {formDaysOfWeek.map(day => (
                  <div
                    className={classes.weekdaysWrapper}
                    key={`timetableWrapper-${day}`}
                  >
                    <Typography
                      data-testid={`maxTravelAdjustmentSelector${day}`}
                      key={day}
                      component="label"
                      htmlFor={`maxTravelChange${day}`}
                    >
                      {day}
                    </Typography>
                    <div className={classes.weekdaySelectWrapper}>
                      <TimeTableSelect
                        field={{
                          ...maxTravelFormWeekdayFields[day],
                          className: classes.weekdaySelect,
                          options: [
                            {
                              value: TemporaryAdjustmentConstants.Empty,
                              key: '',
                              label: ''
                            },
                            ...maxTravelOptions
                          ]
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <FormSpy
                subscription={{
                  values: true
                }}
              >
                {({ values }) => {
                  return formSpyRelevantDays(
                    values,
                    formDates,
                    setValueFormSpy
                  );
                }}
              </FormSpy>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}
