import { InputFieldProps } from 'components/Forms';
import { AdjustmentType, DayOfWeek } from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';
import { getAdjustmentChange, getAdjustmentForDay } from 'utils/adjustments';
import { maxTravelReasonOptions } from 'views/Engineers/Adjustments/AdjustmentFormConfig';
import {
  TemporaryAdjustmentConstants,
  atLeastOneFilledSchema
} from 'views/Engineers/utils';
import * as Yup from 'yup';
import {
  adjustmentCommonFieldsInitialValues,
  adjustmentCommonFieldsValidationSchema,
  adjustmentWeekdayKeys,
  getAdjustmentCommonFields
} from './adjustmentFormCommonConfig';

export const getDayFieldName = (day: string) => `maxTravelChange${day}`;

export const maxTravelToLabel = (maxTravel: number) => `${maxTravel} mins`;

export const maxTravelOptions = [60, 80, 100, 120, 160, 180, 480].map(value => {
  const label = maxTravelToLabel(value);
  return {
    value: value.toString(),
    label,
    key: label
  };
});

const getMaxTravelFieldForWeekday = (day: string): InputFieldProps => {
  const fieldName = getDayFieldName(day);
  return {
    id: fieldName,
    name: fieldName,
    qaId: fieldName,
    disablePlaceholder: true,
    inputType: 'select',
    options: maxTravelOptions
  };
};

export const maxTravelFormCommonFields = getAdjustmentCommonFields(
  maxTravelReasonOptions()
);

export const maxTravelFormWeekdayFields = Object.fromEntries(
  adjustmentWeekdayKeys.map(day => [day, getMaxTravelFieldForWeekday(day)])
);

export const maxTravelFormValidationSchema = Yup.object()
  .shape(adjustmentCommonFieldsValidationSchema)
  .test('atLeastOneFilled', function validOneShiftPattern(formValues) {
    return atLeastOneFilledSchema(
      formValues,
      getDayFieldName,
      this.createError
    );
  });

const getWeekdayMaxTravelFromGroup = (
  day: DayOfWeek,
  adjustmentGroup: AdjustmentGroup
) => {
  const adjustment = getAdjustmentForDay(day, adjustmentGroup.adjustments);
  return adjustment
    ? getAdjustmentChange(adjustment, AdjustmentType.MaxTravel)?.change
    : undefined;
};

export const maxTravelFormWeekdaysInitialValues = (
  adjustmentGroup: AdjustmentGroup | undefined
) => {
  const fieldNameMaxTravelPairs = adjustmentWeekdayKeys.map(day => {
    const adjustmentGroupMaxTravel = adjustmentGroup
      ? getWeekdayMaxTravelFromGroup(
          day.toUpperCase() as DayOfWeek,
          adjustmentGroup
        )
      : null;

    return [
      getDayFieldName(day),
      adjustmentGroupMaxTravel || TemporaryAdjustmentConstants.Empty
    ];
  });

  return {
    ...Object.fromEntries(fieldNameMaxTravelPairs)
  };
};

export const maxTravelFormInitialValues = (
  adjustmentGroup?: AdjustmentGroup
) => {
  const maxTravelDaysInitialValues = maxTravelFormWeekdaysInitialValues(
    adjustmentGroup
  );

  return {
    ...adjustmentCommonFieldsInitialValues(adjustmentGroup?.adjustments[0]),
    ...maxTravelDaysInitialValues
  };
};
