import { InputFieldProps } from 'components/Forms';
import { REQUIRED_FIELD_ERROR_MESSAGE } from 'constants/validation';
import * as Yup from 'yup';

export const selectAdjustmentFormTypeValidationSchema = Yup.object().shape({
  adjustmentFormType: Yup.string().required(REQUIRED_FIELD_ERROR_MESSAGE)
});

export const adjustmentFormTypeFieldName = 'adjustmentFormType';

export enum AdjustmentFormType {
  ShiftChange = 'Shift',
  PatchChange = 'Patch',
  EfficiencyChange = 'Efficiency',
  SkillsChange = 'Skills',
  MaxTravelChange = 'Max travel time'
}

export const getAdjustmentFormTypeField = (): InputFieldProps => ({
  name: adjustmentFormTypeFieldName,
  id: adjustmentFormTypeFieldName,
  qaId: adjustmentFormTypeFieldName,
  inputType: 'select',
  label: 'Type',
  options: Object.values(AdjustmentFormType).map(changeType => ({
    label: `${changeType} change`,
    value: changeType
  }))
});
