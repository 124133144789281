import Adjustment, {
  AdjustmentType,
  DayOfWeek,
  EfficiencyAdjustmentChange,
  PatchAdjustmentChange,
  Pattern,
  PostcodeAdjustmentChange,
  ShiftPatternAdjustmentChange,
  SkillAdjustmentChange,
  MaxTravelAdjustmentChange
} from 'models/Adjustment';
import AdjustmentGroup from 'models/AdjustmentGroup';

type ObjectType<T> = T extends AdjustmentType.Shift
  ? ShiftPatternAdjustmentChange | undefined
  : T extends AdjustmentType.Patch
  ? PatchAdjustmentChange | undefined
  : T extends AdjustmentType.Efficiency
  ? EfficiencyAdjustmentChange | undefined
  : T extends AdjustmentType.Skills
  ? SkillAdjustmentChange | undefined
  : T extends AdjustmentType.MaxTravel
  ? MaxTravelAdjustmentChange | undefined
  : T extends AdjustmentType.Postcode
  ? PostcodeAdjustmentChange | undefined
  : never;

export const getAdjustmentChange = <T extends AdjustmentType>(
  adjustment: Adjustment,
  changeType: T
): ObjectType<T> | undefined =>
  adjustment.changes.find(change => change.field === changeType) as ObjectType<
    T
  >;

const adjustmentTypePriority = [
  AdjustmentType.Shift,
  AdjustmentType.Patch,
  AdjustmentType.Efficiency,
  AdjustmentType.Skills,
  AdjustmentType.MaxTravel
] as const;

export const getPrimaryAdjustmentType = (adjustment: Adjustment) => {
  const type = adjustmentTypePriority.find(adjustmentType =>
    getAdjustmentChange(adjustment, adjustmentType)
  );
  if (!type) {
    throw Error(
      `Unable to determine adjustment type of adjustment ${adjustment.id}`
    );
  }
  return type;
};

export const adjustmentTypeFilter = (type: AdjustmentType) => (
  adjustment: Adjustment
) => getAdjustmentChange(adjustment, type) != undefined;

export const getAdjustmentGroup = (
  adjustments: Adjustment[],
  groupId: string
): AdjustmentGroup => ({
  id: groupId,
  adjustments: adjustments.filter(adjustment => adjustment.groupId === groupId)
});

/**
 * determine whether an adjustment applies to a given day of the week by looking at the adjustments 'pattern'
 * @param adjustment
 * @param dayOfWeek
 * @returns `boolean`
 */
export const doesApplyToDay = (
  adjustment: Adjustment,
  dayOfWeek: DayOfWeek
) => {
  if (adjustment.pattern.type === Pattern.Weekly) {
    return adjustment.pattern.weekdays.includes(dayOfWeek);
  }

  return true;
};

export const getAdjustmentForDay = (
  day: DayOfWeek,
  adjustments: Adjustment[]
) => adjustments.find(adjustment => doesApplyToDay(adjustment, day));
