import * as Yup from 'yup';
import { FormValues, FieldInputValue } from 'components/Forms';
import Adjustment from 'models/Adjustment';
import {
  PatchesSelector,
  ShiftPatternsSelector,
  SkillsSelector,
  TeamsSelector
} from './store';
import { adjustmentWeekdayKeys } from './EngineerSchedule/EditEngineerScheduleModal/adjustmentFormCommonConfig';

export enum TemporaryAdjustmentConstants {
  Empty = 'empty',
  NotWorking = 'not-working'
}
// This is only needed to convert the ID in the dropdown into the array needed by the API.
// This can disappear once the dropdown lets you select multiple skills as it'll already be an array.
const processSkills = (skills: FieldInputValue[]): string[] =>
  skills.map(({ value }) => value);

export const processFormFields = ({
  skills,
  defaultEfficiency,
  ...others
}: FormValues) => ({
  skills: processSkills(skills as FieldInputValue[]),
  defaultEfficiency: parseFloat(defaultEfficiency as string),
  ...others
});

export const atLeastOneFilledSchema = (
  formValues: FormValues,
  getDayFieldName: (day: string) => string,
  createError: (
    params?: Yup.CreateErrorOptions | undefined
  ) => Yup.ValidationError
) => {
  if (
    adjustmentWeekdayKeys
      .map(day => formValues[getDayFieldName(day)])
      .every(x => x === TemporaryAdjustmentConstants.Empty)
  ) {
    return createError({
      message: 'At least one change is required',
      path: 'atLeastOneFilled'
    });
  }
  return true;
};

// We may be able to abstract this out to a generic function which takes in a list of selectors
export const isAnyLoading = (
  skills: SkillsSelector,
  patches: PatchesSelector,
  shiftPatterns: ShiftPatternsSelector,
  teams: TeamsSelector
) =>
  skills.isLoading ||
  patches.isLoading ||
  shiftPatterns.isLoading ||
  teams.isLoading;

export type EngineerAdjustments = Adjustment[];

interface EfficiencyOptions {
  value: string;
  label: string;
  key: string;
}

export const efficiencyToLabel = (efficiency: number) => `${efficiency * 100}%`;

export const efficiencyMultipliers = [
  0,
  0.25,
  0.5,
  0.75,
  1.0,
  1.25,
  1.5,
  2.0,
  3.0
];

export const getEfficiencyOptions = (): EfficiencyOptions[] =>
  efficiencyMultipliers.map(multiplier => {
    const label = efficiencyToLabel(multiplier);
    return {
      value: multiplier.toString(),
      label,
      key: label
    };
  });
