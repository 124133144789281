import {
  AppointmentResponse,
  AppointmentStatus,
  PsrDetails,
  SiteAbortDetails
} from './Appointment';
import { Audit } from './Audit';
import Engineer from './Engineers';
import JobType from './JobTypes';

export interface JobDetailsResponse extends AppointmentResponse {
  assignedEngineer: Engineer | null;
  jobTypeData: JobType;
  enrichedJob: JobEnrichedResponse | null;
  auditEntries: Audit[];
  previousAbortData: SiteAbortDetails | null;
  psrDetails: PsrDetails | null;
}

export enum JobSheetSectionLabels {
  Complaint = 'Complaint',
  Customer = 'Customer',
  Booking = 'Booking',
  Technical = 'Technical',
  PreviousAborts = 'Previous Aborts'
}
export interface JobSheetData {
  label: JobSheetSectionLabels;
  rows: JobSheetDataRowsOptions[];
}

export enum IconType {
  'VULNERABLE',
  'INFO'
}

export interface JobSheetDataRowsOptions {
  type: string;
  label: string;
  value: string | string[];
  icon?: IconType;
  onClick?: () => void;
  onClickRowValueIndex?: number;
}

export interface JobSheetHeaderData {
  status: AppointmentStatus;
  slot?: string;
  postcode?: string;
  engineerFirstName?: string;
  engineerLastName?: string;
  atRisk: boolean;
}

export interface JobEnrichedResponse {
  engineer: {
    id: string;
  };
  metadata: {
    version: string;
    type: string;
    appointmentId: string;
  };
  data: {
    sections: EnrichedResponseSection[];
    notes: EnrichedResponseNote[];
  };
}

export interface EnrichedResponseNote {
  text: string;
  media: EnrichedResponseMedia[];
  createdAt: string;
  changedAt: string;
}

export interface EnrichedResponseSection {
  id: string;
  description: string;
  metadata: {
    timestamp: string;
  };
  questions: EnrichedResponseQuestion[];
}

export interface EnrichedResponseQuestion {
  id: string;
  description: string;
  text: string;
  media: EnrichedResponseMedia[];
}

interface EnrichedResponseMedia {
  url: string;
  createdAt: string;
  addedAt: string;
}
export interface PresignedImageData {
  url: string;
  key: string;
}
