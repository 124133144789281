import http from 'api/http';
import { psrDomain } from 'models';
import { PsrDetails } from 'models/Appointment';
import { createUrl, commonErrorHandling } from 'store/utils';

export async function fetchPsrDetails(
  accountNumber: string | null,
  aegisDisplayPsrVulnerabilities?: boolean
): Promise<PsrDetails | null> {
  if (!aegisDisplayPsrVulnerabilities || accountNumber === null) {
    return null;
  }
  try {
    const { data: psrDetails } = await http.get<PsrDetails>(
      `${createUrl(psrDomain, null, accountNumber)}`
    );

    return psrDetails;
  } catch (error) {
    commonErrorHandling(error, psrDomain.type);

    return null;
  }
}
