import React, { useState } from 'react';
import { makeStyles, Chip, Box, Paper } from '@material-ui/core';
import Phone from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import Typography from 'components/Typography';
import { useEngineerData } from 'store/selectors';
import Button from 'components/Button';
import Skill from 'models/Skills';
import EditEngineerModal from 'views/Engineers/EditEngineer/EditEngineerModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface EngineerCardProps {
  engineerId: string;
}

const engineerDetailsCard = makeStyles(theme => ({
  paper: {
    width: theme.containers.max,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5)
  },
  leftColumn: {
    padding: theme.spacing(2),
    width: '50%',
    borderRight: `1px solid ${theme.palette.aegis.semantic.border.light}`
  },
  middleColumn: {
    padding: theme.spacing(2, 3),
    borderRight: `1px solid ${theme.palette.aegis.semantic.border.light}`
  },
  rightColumn: {
    padding: theme.spacing(2, 3)
  },
  fullNameLabel: {
    marginBottom: theme.spacing(2),
    color: theme.palette.aegis.semantic.message.black
  },
  label: {
    color: theme.palette.aegis.semantic.message.black
  },
  labelValue: {
    color: theme.palette.aegis.semantic.message.dark,
    paddingBottom: theme.spacing(2)
  },
  labelWithIcon: {
    paddingBottom: theme.spacing(2)
  },
  labelWithIconValue: {
    color: theme.palette.aegis.semantic.message.dark
  },
  verticalSpacer: {
    height: theme.spacing(5)
  },
  button: {
    padding: theme.spacing(0, 2),
    alignSelf: 'flex-end'
  },
  iconEmail: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.aegis.semantic.message.dark,
    marginRight: theme.spacing(1)
  },
  iconPhone: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.aegis.semantic.message.dark,
    marginRight: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(1)
  }
}));

export default function EngineerCard({ engineerId }: EngineerCardProps) {
  const engineerCardClasses = engineerDetailsCard();
  const engineerData = useEngineerData(engineerId);

  const [isEditEngineerModalOpen, setIsEditEngineerModalOpen] = useState(false);

  const flags = useFlags();

  const onEngineerEdit = () => {
    setIsEditEngineerModalOpen(true);
  };

  const onEngineerEditModalClose = () => {
    setIsEditEngineerModalOpen(false);
  };

  return engineerData === undefined ? null : (
    <>
      <EditEngineerModal
        engineer={engineerData}
        open={isEditEngineerModalOpen}
        onClose={onEngineerEditModalClose}
      />
      <Paper
        className={engineerCardClasses.paper}
        data-testid="engineerDetailsCard"
        elevation={2}
      >
        <Box
          className={engineerCardClasses.leftColumn}
          display="flex"
          flexDirection="column"
          flexGrow={0}
        >
          <Typography
            variant="h2"
            align="left"
            className={engineerCardClasses.fullNameLabel}
            data-testid="engineerName"
          >
            {engineerData.firstName} {engineerData.lastName}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={engineerCardClasses.labelWithIcon}
          >
            <EmailIcon
              fontSize="small"
              className={engineerCardClasses.iconEmail}
              data-testid="emailIcon"
            />
            <Typography
              className={engineerCardClasses.labelWithIconValue}
              data-testid="engineerEmailAddress"
            >
              {engineerData.emailAddress || 'Email address missing'}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={engineerCardClasses.labelWithIcon}
          >
            <Phone
              fontSize="small"
              className={engineerCardClasses.iconPhone}
              data-testid="phoneIcon"
            />
            <Typography
              className={engineerCardClasses.labelWithIconValue}
              data-testid="engineerPhoneNumber"
            >
              {engineerData.phoneNumber}
            </Typography>
          </Box>
          <Box data-testid="engineerSkills">
            {engineerData.skills.map((skill: Skill) => (
              <Chip
                key={skill.id}
                label={<Typography>{skill.name}</Typography>}
                className={engineerCardClasses.chip}
              />
            ))}
          </Box>
        </Box>
        <Box
          className={engineerCardClasses.middleColumn}
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <Box className={engineerCardClasses.verticalSpacer} />
          <Box className={engineerCardClasses.label}>
            <Typography>Postcode:</Typography>
          </Box>
          <Typography
            className={engineerCardClasses.labelValue}
            data-testid="engineerPostcode"
          >
            {engineerData.postcode}
          </Typography>

          <Typography className={engineerCardClasses.label}>
            ByBox location:
          </Typography>

          <Typography
            className={engineerCardClasses.labelValue}
            data-testid="engineerByBox"
          >
            {engineerData.byBox}
          </Typography>
        </Box>
        <Box
          className={engineerCardClasses.rightColumn}
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <Box textAlign="right">
            <Button
              color="primary"
              className={engineerCardClasses.button}
              onClick={onEngineerEdit}
              data-testid="domainItemEdit"
            >
              Edit details
            </Button>
          </Box>
          <Typography className={engineerCardClasses.label}>Team:</Typography>

          <Typography
            className={engineerCardClasses.labelValue}
            data-testid="engineerTeam"
          >
            {engineerData.team?.name || 'Team missing'}
          </Typography>

          <Typography className={engineerCardClasses.label}>
            Company:
          </Typography>

          <Typography
            className={engineerCardClasses.labelValue}
            data-testid="engineerCompany"
          >
            {engineerData.company}
          </Typography>

          {flags?.aegisEnableMaxTravelTimesDto ? (
            <>
              <Typography className={engineerCardClasses.label}>
                Max travel time:
              </Typography>

              <Typography
                className={engineerCardClasses.labelValue}
                data-testid="engineerMaxTravelTime"
              >
                {engineerData.maxTravelTime}
              </Typography>
            </>
          ) : (
            <>
              <Typography className={engineerCardClasses.label}>
                Vehicle type:
              </Typography>

              <Typography
                className={engineerCardClasses.labelValue}
                data-testid="engineerVehicleType"
              >
                {engineerData.vehicleType}
              </Typography>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}
